import React from 'react'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom'

import style from './css/footer.module.css'

import { FaLocationDot } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FaTwitterSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";


const Footer = () => {
  return (
    <>
    <Link to="https://wa.me/+2348134370701" className={style.whatsappLink}><FaWhatsappSquare className={style.whatsappIcon}/></Link>
    <footer>
        <div className={style.mainContentstyle}>
            <div className={style.box}>
                <h3 className={style.titleText}>Get in touch</h3>
                <div className={style.content}>
                    <div className={style.phone}>
                    <FaPhone />
                    <span><a href="tel:+2348134370701" className={style.text}>+2348134370701</a><br /><a href="tel:+2347032734515" className={style.text}>+2347032734515</a></span>
                    </div>
                    <div className={style.emails}>
                    <FaEnvelope />
                    <span
                        ><a href="mailto:info@aptechlekki.com?subject=Enquiry" className={style.text}>info@aptechlekki.com</a><br /><a href="mailto:aptechlekki@gmail.com?subject=Enquiry" className={style.text}>aptechlekki@gmail.com</a></span>
                    </div>
                </div>
                <div className={style.social}>
                <Link to="https://www.facebook.com/OfficialAptechLekki" className={style.socialLink}><FaSquareFacebook /></Link>
                <Link to="https://twitter.com/aptechlekki" className={style.socialLink}><FaTwitterSquare /></Link>
                <Link to="https://www.instagram.com/aptechlekkicentre/" className={style.socialLink}><FaInstagramSquare /></Link>
                <Link to="https://www.linkedin.com/company/aptech-lekki-centre/" className={style.socialLink}><FaLinkedin /></Link>
                </div> 
            </div>
            <div className={style.box}>
                <h3 className={style.titleText}>Address</h3>
                <div className={style.content}>
                <div className={style.place}>
                <FaLocationDot className={style.locationIcon}/>
                <span className={style.text}>2nd Floor, Brasas "R" Place<br />
                    Admiralty Way, Lekki Phase 1, Lagos - Nigeria.</span>
                </div>
                </div>
            </div>
            <div className={`${style.box} ${style.subscribeForm}`}>
                <h3 className={style.titleText}>Quick Links</h3>
                <ul>
                    <li className={style.footerLinks}><NavLink exact="true" to="/" className={style.footerLink}>Home</NavLink></li>
                    <li className={style.footerLinks}><NavLink exact="true" to="/about" className={style.footerLink}>About Us</NavLink></li>
                    <li className={style.footerLinks}><NavLink exact="true" to="/about#faq" className={style.footerLink}>FAQ</NavLink></li>
                    <li className={style.footerLinks}><NavLink exact="true" to="/courses" className={style.footerLink}>Our Courses</NavLink></li>
                    <li className={style.footerLinks}><NavLink exact="true" to="/events" className={style.footerLink}>Our Events</NavLink></li>
                    <li className={style.footerLinks}><NavLink exact="true" to="/apply" className={style.footerLink}>Apply Now</NavLink></li>
                </ul>
            </div>
        </div>
        <p className={style.copyright}>Copyright &copy; 2024 Aptech Lekki Centre</p>
    </footer>
    </>
  )
}

export default Footer
